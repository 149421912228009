import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function AwardsView({ award }): JSX.Element {
  const { name, years, description, url } = award;
  const clickable = url !== "" ? "clickable" : "unclickable";
  const guts = (
    <li>
      <div className={`section-list-item ${clickable}`}>
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{name}</div>
          <div>{description}</div>
        </div>

        <div className="section-list-item__year">{years}</div>
      </div>
    </li>
  );
  return url !== "" ? (
    <a href={url} target="_blank" rel="noreferrer">
      {guts}
    </a>
  ) : (
    <div>{guts}</div>
  );
}

function AwardsList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query AwardsQuery {
      allAwardsJson {
        edges {
          node {
            years
            url
            name
            description
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allAwardsJson.edges
        .sort((edge) => -edge.node.start)
        .map((edge) => (
          <AwardsView award={edge.node} />
        ))}
    </ol>
  );
}
export default AwardsList;
