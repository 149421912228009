import React from "react";
import { graphql, useStaticQuery } from "gatsby";

function EducationView({ education }): JSX.Element {
  const { institution, degree, topic, start, end, details } = education;
  console.log(details);

  return (
    <li>
      <div className="section-list-item">
        <div className="section-list-item__info">
          <div className="section-list-item__info title">
            {degree} in {topic}
          </div>
          <div>{institution}</div>
          {details.map((d) => (
            <div>{d}</div>
          ))}
        </div>
        <div className="section-list-item__year">
          {start} - {end}
        </div>
      </div>
    </li>
  );
}

function EducationList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query EducationQuery {
      allEducationJson {
        edges {
          node {
            institution
            degree
            topic
            start
            end
            details
          }
        }
      }
    }
  `);

  return (
    <ul className="section-list">
      {data.allEducationJson.edges
        .sort((edge) => parseInt(edge.node.start))
        .map((edge) => (
          <EducationView education={edge.node} />
        ))}
    </ul>
  );
}
export default EducationList;
