import React from "react";
import { renderAuthors } from "../../utils";

function PresentationView({ presentation }): JSX.Element {
  const { title, authors, journal, url, year } = presentation;
  const clickable = url !== "" ? "clickable" : "unclickable";
  const guts = (
    <li>
      <div className={`section-list-item ${clickable}`}>
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{title}</div>
          {renderAuthors(authors)}
          <em>{journal}</em>
        </div>
        <div className="section-list-item__year">{year}</div>
      </div>
    </li>
  );

  return url !== "" ? (
    <a href={url} target="_blank" rel="noreferrer">
      {guts}
    </a>
  ) : (
    <div>{guts}</div>
  );
}
export default PresentationView;
