import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../components/BackButtonHeader";
import {
  AwardsList,
  EducationList,
  MentorshipList,
  ScicommList,
  ServiceList,
  TeachingList,
  TrainingList,
  OralsList,
  PosterList,
  InvitedList,
  PublicationsList,
  PreprintList,
} from "../components/CV";

import { FaFilePdf } from "react-icons/fa";
import "../cv.scss";

function PDFDownload(): JSX.Element {
  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "2024.January.CV.pdf" }) {
        publicURL
        name
      }
    }
  `);
  return (
    <a href={data.file.publicURL} download>
      <FaFilePdf size="1.5em" /> Download CV as PDF
    </a>
  );
}

function CurriculumVitae(): JSX.Element {
  return (
    <div className="cv">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | CV</title>
        <html lang="en" />
      </Helmet>
      <div className="header_download">
        <BackButtonHeader title="Curriculum Vitae" />
        <div className="download">
          <PDFDownload />
        </div>
      </div>

      <section className="education">
        <h2>Education</h2>
        <EducationList />
      </section>
      <hr />

      <section className="training">
        <h2>Training</h2>
        <TrainingList />
      </section>
      <hr />

      <section className="publications">
        <h2>Publications</h2>
        <h3>Peer-reviewed</h3>
        <PublicationsList oldestFirst={false}/>

        <h3>Preprints</h3>
        <PreprintList oldestFirst={false}/>
      </section>
      <hr />

      <section className="awards">
        <h2>Awards and Honors</h2>
        <AwardsList />
      </section>
      <hr />

      <section className="presentations">
        <h2>Presentations</h2>
        <h3>Oral Presentations</h3>
        <OralsList />

        <h3>Posters</h3>
        <PosterList />

        <h3>Invited Talks</h3>
        <InvitedList />
      </section>
      <hr />

      <section className="teaching">
        <h2>Teaching</h2>
        <TeachingList />
      </section>
      <hr />

      <section className="mentorship">
        <h2>Mentorship</h2>
        <MentorshipList />
      </section>
      <hr />

      <section className="service">
        <h2>Service</h2>
        <ServiceList />
      </section>
      <hr />

      <section className="scicomm">
        <h2>Scientific Communication</h2>
        <ScicommList />
      </section>
    </div>
  );
}

export default CurriculumVitae;
