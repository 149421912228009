import React from "react";
const renderAuthor = (author) => {
  if (author === "Margalit, E.") {
    return <strong className="accent">{author}</strong>;
  } else {
    return <span>{author}</span>;
  }
};

export const renderAuthors = (authors) => {
  return authors.map((author, i) => {
    var author_info = renderAuthor(author);

    let to_render;

    if (i === authors.length - 1) {
      // last
      if (authors.length === 1) {
        to_render = (
          <span>
            {author_info}
            <br />
          </span>
        );
      } else {
        to_render = (
          <span>
            and {author_info}
            <br />
          </span>
        );
      }
    } else if (i === authors.length - 2) {
      // penultimate
      to_render = (
        <span>
          {author_info}
          {` `}
        </span>
      );
    } else {
      //all others
      to_render = (
        <span>
          {author_info},{` `}
        </span>
      );
    }
    return <span key={authors.tag + author}>{to_render}</span>;
  });
};
