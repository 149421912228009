import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function ServiceView({ service }): JSX.Element {
  const { role, years } = service;
  return (
    <li>
      <div className="section-list-item">
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{role}</div>
        </div>

        <div className="section-list-item__year">{years}</div>
      </div>
    </li>
  );
}

function ServiceList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query ServiceQuery {
      allServiceJson {
        edges {
          node {
            years
            role
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allServiceJson.edges
        .sort((edge) => -edge.node.start)
        .map((edge) => (
          <ServiceView service={edge.node} />
        ))}
    </ol>
  );
}
export default ServiceList;
