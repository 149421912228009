import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PresentationView from "./PresentationView";

function PosterList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query PosterPresentationQuery {
      allPostersJson {
        edges {
          node {
            title
            authors
            journal
            url
            year
            tag
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allPostersJson.edges
        .sort((edge) => edge.node.year)
        .map((edge) => (
          <PresentationView presentation={edge.node} />
        ))}
    </ol>
  );
}
export default PosterList;
