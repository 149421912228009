import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PresentationView from "./PresentationView";

function OralsList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query OralPresentationQuery {
      allOralsJson {
        edges {
          node {
            title
            authors
            journal
            url
            year
            tag
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allOralsJson.edges
        .sort((edge) => -edge.node.year)
        .map((edge) => (
          <PresentationView presentation={edge.node} />
        ))}
    </ol>
  );
}
export default OralsList;
