import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function TrainingView({ training }): JSX.Element {
  const { labName, pi, url, start, end, description } = training;
  const clickable = url !== "" ? "clickable" : "unclickable";
  const guts = (
    <li>
      <div className={`section-list-item ${clickable}`}>
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{labName}</div>
          <div>
            PI: {pi} | {description}{" "}
          </div>
        </div>

        <div className="section-list-item__year">
          {start} - {end}
        </div>
      </div>
    </li>
  );
  return url !== "" ? (
    <a href={url} target="_blank" rel="noreferrer">
      {guts}
    </a>
  ) : (
    <div>{guts}</div>
  );
}

function TrainingList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query TrainingQuery {
      allTrainingJson {
        edges {
          node {
            labName
            pi
            url
            start
            end
            description
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allTrainingJson.edges
        .sort((edge) => edge.node.start)
        .map((edge) => (
          <TrainingView training={edge.node} />
        ))}
    </ol>
  );
}
export default TrainingList;
