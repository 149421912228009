import React from "react";
import { renderAuthors } from "../../utils";

function PublicationView({ publication }): JSX.Element {
  const { title, authors, journal, volume, pages, url, year } = publication;
  return (
    <li>
      <a href={url} target="_blank" rel="noreferrer">
        <div className="section-list-item clickable">
          <div className="section-list-item__info">
            <div className="section-list-item__info title">{title}</div>
            {renderAuthors(authors)}
            <em>
              {journal}
              {volume}
              {pages}
            </em>
          </div>
          <div className="section-list-item__year">{year}</div>
        </div>
      </a>
    </li>
  );
}
export default PublicationView;
