import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function ScicommView({ scicomm }): JSX.Element {
  const { title, description, url } = scicomm;
  const clickable = url !== "" ? "clickable" : "unclickable";
  const guts = (
    <li>
      <div className={`section-list-item ${clickable}`}>
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{title}</div>
          <div>{description}</div>
        </div>
      </div>
    </li>
  );
  return url !== "" ? (
    <a href={url} target="_blank" rel="noreferrer">
      {guts}
    </a>
  ) : (
    <div>{guts}</div>
  );
}

function ScicommList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query ScicommQuery {
      allScicommJson {
        edges {
          node {
            title
            description
            url
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allScicommJson.edges
        .sort((edge) => -edge.node.start)
        .map((edge) => (
          <ScicommView scicomm={edge.node} />
        ))}
    </ol>
  );
}
export default ScicommList;
