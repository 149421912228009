import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function MentorshipView({ mentorship }): JSX.Element {
  const { start, end, role } = mentorship;
  return (
    <li>
      <div className="section-list-item">
        <div className="section-list-item__info">
          <div className="section-list-item__info title">{role}</div>
        </div>

        <div className="section-list-item__year">
          {start} - {end}
        </div>
      </div>
    </li>
  );
}

function MentorshipList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query MentorshipQuery {
      allMentorshipJson {
        edges {
          node {
            start
            end
            role
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allMentorshipJson.edges
        .sort((edge) => -edge.node.start)
        .map((edge) => (
          <MentorshipView mentorship={edge.node} />
        ))}
    </ol>
  );
}
export default MentorshipList;
