import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PublicationView from "./PublicationView";

function PublicationsList(): JSX.Element {
  const data = useStaticQuery(graphql`
    query PublicationsQuery {
      allPublicationsJson {
        edges {
          node {
            authors
            journal
            pages
            tag
            title
            url
            volume
            year
          }
        }
      }
    }
  `);

  return (
    <ol className="section-list">
      {data.allPublicationsJson.edges
        .map((edge) => (
          <PublicationView publication={edge.node} />
        ))}
    </ol>
  );
}
export default PublicationsList;
